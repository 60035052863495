import React from 'react'

export default function TableItem({
  item,
  removeText = 'REMOVE',
  onChangeQuantity,
  onRemove,
  className,
}) {
  const variantImage = item.imgSrc ? (
    <img
      src={item.imgSrc}
      alt={`${item.title} product shot`}
      className="object-contain"
    />
  ) : null

  const itemUnitPrice = item.unitPrice
  const itemQuantity = item.quantity
  const itemTotalPrice = item.totalPrice
  const variantTitle = item.variantTitle

  const handleChangeQuantity = ({ target }) => {
    if (onChangeQuantity) {
      onChangeQuantity(target.value)
    }
  }

  return (
    <tr className={className}>
      <td>
        <div className="flex flex-row items-center">
          <div className="cart-img flex justify-center">{variantImage}</div>
          <div className="ml-4 flex flex-col items-start space-y-2">
            <p className="text-left">{item.title}</p>
            {variantTitle != null && variantTitle !== '' && (
              <p className="text-left text-base">{variantTitle}</p>
            )}
            <button
              onClick={onRemove}
              className="link-btn text-xs underline focus:outline-none"
            >
              {removeText}
            </button>
          </div>
        </div>
      </td>
      <td>
        <p>${itemUnitPrice}</p>
      </td>
      <td>
        <div className="flex justify-center items-center">
          <div className="w-12 h-12 bg-input flex items-center justify-center">
            <label htmlFor="quantity" className="hidden">
              Amount
            </label>
            <input
              className="appearance-none outline-none focus:outline-none w-8 h-full bg-input text-white"
              type="number"
              name="quantity"
              min="0"
              max="99"
              value={itemQuantity}
              onChange={handleChangeQuantity}
            />
          </div>
        </div>
      </td>
      <td>
        <p>${itemTotalPrice}</p>
      </td>
    </tr>
  )
}
