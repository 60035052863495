import React from 'react'
import _ from 'lodash'
import TableItem from './TableItem'

export default function CartTable({
  cartPrismicData,
  items,
  onChangeQuantity,
  onRemove,
  isLoading,
  className,
}) {
  const itemsColumn = _.get(
    cartPrismicData,
    'items_column_text.text',
    'Your Items'
  )
  const priceColumn = _.get(cartPrismicData, 'price_column_text.text', 'Price')
  const quantityColumn = _.get(
    cartPrismicData,
    'quantity_column_text.text',
    'Quantity'
  )
  const totalColumn = _.get(cartPrismicData, 'total_column_text.text', 'Total')

  const removeText = _.get(cartPrismicData, 'remove_link_text.text', 'Remove')

  return (
    <>
      <table className="table-fixed w-full">
        <thead className="table-head">
          <tr>
            <th className="w-3/6 text-left text-gray-title">{itemsColumn}</th>
            <th className="w-1/6 text-gray-title">{priceColumn}</th>
            <th className="w-1/6 text-gray-title">{quantityColumn}</th>
            <th className="w-1/6 text-gray-title">{totalColumn}</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, idx) => {
            // TODO - If item is bottle fee, return null.
            if (item.isBottleFeeItem) {
              return null
            }
            return (
              <TableItem
                key={item.id.toString()}
                item={item}
                removeText={removeText}
                onChangeQuantity={quantity => {
                  if (onChangeQuantity) {
                    onChangeQuantity(idx, quantity)
                  }
                }}
                onRemove={() => {
                  if (onRemove) {
                    onRemove(idx)
                  }
                }}
              />
            )
          })}
        </tbody>
      </table>
      {isLoading && (
        <div className="bg-white bg-opacity-50 w-full h-full absolute top-0 left-0 flex items-center justify-center">
          <svg
            className="animate-spin ml-2 h-5 w-5 text-yellow"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      )}
    </>
  )
}
