import React from 'react'
import clsx from 'clsx'

export default function ListItem({
  item,
  priceText = 'Price',
  quantityText = 'Quantity',
  totalText = 'Total',
  removeText = 'Remove',
  onChangeQuantity,
  onRemove,
  className,
}) {
  const variantImage = item.imgSrc ? (
    <img
      className="cart-img object-contain"
      src={item.imgSrc}
      alt={`${item.title} product shot`}
    />
  ) : null

  const itemUnitPrice = item.unitPrice
  const itemQuantity = item.quantity
  const itemTotalPrice = item.totalPrice
  const variantTitle = item.variantTitle

  const handleChangeQuantity = ({ target }) => {
    if (onChangeQuantity) {
      onChangeQuantity(target.value)
    }
  }

  return (
    <div
      className={clsx(
        'shopping-cart-item flex flex-col items-center',
        className
      )}
    >
      {variantImage}
      <p className="mt-2 text-center">{item.title}</p>
      {variantTitle != null && variantTitle !== '' && (
        <p className="mt-2 text-center text-base">{variantTitle}</p>
      )}
      <button
        onClick={onRemove}
        className="mt-4 link-btn underline focus:outline-none"
      >
        {removeText}
      </button>
      <div className="mt-4 self-stretch flex justify-between items-center">
        <p>{priceText}</p>
        <p>${itemUnitPrice}</p>
      </div>
      <div className="mt-4 self-stretch flex justify-between items-center">
        <p>{quantityText}</p>
        <div className="w-12 h-12 bg-input flex items-center justify-center">
          <label htmlFor="quantity" className="hidden">
            Amount
          </label>
          <input
            className="appearance-none outline-none focus:outline-none w-8 h-full bg-input text-white"
            type="number"
            name="quantity"
            min="0"
            max="99"
            value={itemQuantity}
            onChange={handleChangeQuantity}
          />
        </div>
      </div>
      <div className="mt-4 self-stretch flex justify-between items-center">
        <p>{totalText}</p>
        <p>${itemTotalPrice}</p>
      </div>
    </div>
  )
}
