import React from 'react'
import _ from 'lodash'
import ListItem from './ListItem'

export default function CartList({
  cartPrismicData,
  items,
  onChangeQuantity,
  onRemove,
  isLoading,
  className,
}) {
  const priceColumn = _.get(cartPrismicData, 'price_column_text.text', 'Price')
  const quantityColumn = _.get(
    cartPrismicData,
    'quantity_column_text.text',
    'Quantity'
  )
  const totalColumn = _.get(cartPrismicData, 'total_column_text.text', 'Total')

  const removeText = _.get(cartPrismicData, 'remove_link_text.text', 'Remove')

  return (
    <>
      {items.map((item, idx) => {
        if (item.isBottleFeeItem) {
          return null
        }

        return (
          <ListItem
            key={item.id.toString()}
            item={item}
            priceText={priceColumn}
            quantityText={quantityColumn}
            totalText={totalColumn}
            removeText={removeText}
            onChangeQuantity={quantity => {
              if (onChangeQuantity) {
                onChangeQuantity(idx, quantity)
              }
            }}
            onRemove={() => {
              if (onRemove) {
                onRemove(idx)
              }
            }}
          />
        )
      })}
      {isLoading && (
        <div className="bg-white bg-opacity-50 w-full h-full absolute top-0 left-0 flex items-center justify-center">
          <svg
            className="animate-spin ml-2 h-5 w-5 text-yellow"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      )}
    </>
  )
}
