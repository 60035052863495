import React from 'react'
import ResolvingLink from '../ResolvingLink'
import _ from 'lodash'

export default function EmptyCart({ cartPrismicData, className }) {
  const title = _.get(cartPrismicData, 'title.text', 'Shopping Cart')
  const description = _.get(
    cartPrismicData,
    'empty_description.text',
    'Your cart is currently empty.'
  )
  const buttonText = _.get(cartPrismicData, 'button_text.text', 'GO TO SHOP')
  const buttonLink = _.get(cartPrismicData, 'button_link')

  return (
    <section className="container mx-auto flex flex-col items-center px-4 md:px-2">
      <h1 className="text-gray-title">{title}</h1>
      <p className="text-gray-body">{description}</p>
      <ResolvingLink link={buttonLink} className="mt-6 btn btn-yellow-filled">
        {buttonText}
      </ResolvingLink>
    </section>
  )
}
