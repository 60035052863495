import React from 'react'
import clsx from 'clsx'
import _ from 'lodash'
import SpinnableButton from '../shared/SpinnableButton'

export default function CartButtons({
  cartPrismicData,
  isShowUpdate,
  isLoading,
  onUpdate,
  onCheckout,
  isAgreed,
  className,
}) {
  const updateButtonText = _.get(
    cartPrismicData,
    'update_button_text.text',
    'UPDATE CART'
  )

  const checkoutButtonText = _.get(
    cartPrismicData,
    'checkout_button_text.text',
    'CHECKOUT'
  )

  return (
    <div className={clsx('flex flex-col', className)}>
      {isShowUpdate && (
        <SpinnableButton
          onClick={onUpdate}
          isLoading={isLoading}
          className="btn-yellow-filled justify-center mb-1 md:mb-4"
        >
          {updateButtonText}
        </SpinnableButton>
      )}
      <SpinnableButton
        onClick={onCheckout}
        isLoading={isLoading}
        isDisabled={isAgreed === false}
        className="btn-yellow-filled justify-center"
      >
        {checkoutButtonText}
      </SpinnableButton>
    </div>
  )
}
