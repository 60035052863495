import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
import PageLayout from '../components/PageLayout'
import Cart from '../components/Cart/Cart'
import SEO from '../components/seo'

export const query = graphql`
  query CartPageQuery($uid: String, $lang: String) {
    prismicCartPage(uid: { eq: $uid }, lang: { eq: $lang }) {
      uid
      id
      data {
        back_to_shop_link {
          url
          target
          link_type
        }
        back_to_shop_link_text {
          text
        }
        bottle_deposit_text {
          text
        }
        button_link {
          url
          link_type
          target
        }
        button_text {
          text
        }
        calculated_at_next_step_text {
          text
        }
        checkout_button_text {
          text
        }
        empty_description {
          text
        }
        items_column_text {
          text
        }
        page_title {
          text
        }
        page_description {
          text
        }
        price_column_text {
          text
        }
        quantity_column_text {
          text
        }
        remove_link_text {
          text
        }
        shipping_estimate_text {
          text
        }
        subtitle {
          text
        }
        subtotal_text {
          text
        }
        taxes_text {
          text
        }
        terms_checkbox_text {
          raw
        }
        title {
          text
        }
        total_column_text {
          text
        }
        total_text {
          text
        }
        update_button_text {
          text
        }
      }
    }
    prismicNavigationBar(lang: { eq: $lang }) {
      ...NavigationBarFragment
    }
    prismicLeftNavigationMenu(lang: { eq: $lang }) {
      ...LeftNavigationMenuFragment
    }
    prismicRightNavigationMenu(lang: { eq: $lang }) {
      ...RightNavigationMenuFragment
    }
    prismicStayConnectedSection(lang: { eq: $lang }) {
      ...StayConnectedSectionFragment
    }
    prismicBottomNavigationBar(lang: { eq: $lang }) {
      ...BottomNavigationBarFragment
    }
    prismicCookieBanner(lang: { eq: $lang }) {
      ...CookieBannerFragment
    }
  }
`

export default function CartPage({ data }) {
  const { prismicCartPage: cartPage } = data

  if (cartPage == null || cartPage.data == null) {
    // TODO - Generate 404
    return null
  }

  const pageTitle = _.get(cartPage, 'data.page_title.text')
  const pageDescription = _.get(cartPage, 'data.page_description.text')

  return (
    <PageLayout pageData={data}>
      <SEO title={pageTitle} description={pageDescription} />
      <div className="min-h-screen mt-navbar">
        <Cart cartPrismicData={cartPage.data} />
      </div>
    </PageLayout>
  )
}
