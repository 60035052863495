import React, { useContext, useState } from 'react'
import ResolvingLink from '../ResolvingLink'
import _ from 'lodash'
import CartTable from './CartTable'
import CartDetails from './CartDetails'
import CartButtons from './CartButtons'
import CartList from './CartList'
import StoreContext from '../../context/StoreContext'
import getPrice from '../../utils/getPrice'
import {
  convertRawItemsToItems,
  deepEqual,
  getBottleFeePriceFromItems,
  getItemsToRemove,
  getItemsToUpdate,
} from '../../utils/cart-utils'

export default function FilledCart({ cartPrismicData, className }) {
  const {
    updateLineItems,
    store: { checkout },
  } = useContext(StoreContext)

  const items = convertRawItemsToItems(checkout?.lineItems)

  const [currentItems, setCurrentItems] = useState(items)
  const [isUpdating, setIsUpdating] = useState(false)
  const [isAgreed, setIsAgreed] = useState(false)

  const handleChangeQuantity = (idx, quantity) => {
    if (idx < 0 || idx >= currentItems.length) {
      return
    }

    const parsedQuantity = parseInt(quantity, 10)

    if (isNaN(parsedQuantity)) {
      return
    }

    const currItem = currentItems[idx]
    // const totalPrice =
    //   quantity <= 0
    //     ? '0.00'
    //     : getPrice(parseFloat(currItem.unitPrice ?? '0') * parsedQuantity)

    const newItem = {
      ...currItem,
      quantity: parsedQuantity,
      // totalPrice,
    }

    const copy = [...currentItems]

    copy.splice(idx, 1, newItem)

    setCurrentItems(copy)
  }

  const isNeedsUpdate = !deepEqual(items, currentItems)

  const handleUpdate = async () => {
    if (!isNeedsUpdate) {
      return
    }

    const itemsToUpdate = getItemsToUpdate(items, currentItems)

    if (itemsToUpdate.length === 0) {
      return
    }

    setIsUpdating(true)

    try {
      const newRawItems = await updateLineItems(checkout.id, itemsToUpdate)
      setCurrentItems(convertRawItemsToItems(newRawItems))
    } catch (e) {
      console.error(e)
    }

    setIsUpdating(false)
  }

  const handleOnRemove = async idx => {
    if (idx == null) {
      return
    }

    const itemsToUpdate = getItemsToRemove(items, currentItems, idx)

    if (itemsToUpdate.length === 0) {
      return
    }

    setIsUpdating(true)
    try {
      const newRawItems = await updateLineItems(checkout.id, itemsToUpdate)
      setCurrentItems(convertRawItemsToItems(newRawItems))
    } catch (e) {
      console.error(e)
    }
    setIsUpdating(false)
  }

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const bottleFeePrice = getBottleFeePriceFromItems(items)
  const subtotalPrice = parseFloat(checkout.subtotalPrice) - bottleFeePrice

  const title = _.get(cartPrismicData, 'title.text', 'Shopping Cart')
  const description = _.get(
    cartPrismicData,
    'subtitle.text',
    'Please review the contents of your cart. When you have finished please proceed to the check out.'
  )
  const backToShopLinkText = _.get(
    cartPrismicData,
    'back_to_shop_link_text.text',
    'Back to Shop'
  )
  const backToShopLink = _.get(cartPrismicData, 'back_to_shop_link')

  return (
    <>
      <section className="container mx-auto flex flex-col px-4 md:px-2">
        <h1 className="text-gray-title">{title}</h1>
        <p className="text-gray-body">{description}</p>
      </section>
      <section className="container mx-auto mt-4 mb-16 flex flex-col items-center md:flex-row md:items-start">
        <div className="shopping-cart-table mt-8 md:w-4/6 relative hidden md:block md:mt-0">
          <CartTable
            cartPrismicData={cartPrismicData}
            items={currentItems}
            onChangeQuantity={handleChangeQuantity}
            onRemove={handleOnRemove}
            isLoading={isUpdating}
          />
        </div>
        <div className="w-full mt-8 px-4 relative space-y-4 md:hidden">
          <CartList
            cartPrismicData={cartPrismicData}
            items={currentItems}
            onChangeQuantity={handleChangeQuantity}
            onRemove={handleOnRemove}
            isLoading={isUpdating}
          />
        </div>
        <div className="shopping-cart-details bg-white w-full mt-4 md:w-2/6 px-4 py-2 flex flex-col md:mt-0">
          <CartDetails
            cartPrismicData={cartPrismicData}
            subtotalPrice={getPrice(subtotalPrice)}
            bottleFeePrice={getPrice(bottleFeePrice)}
            totalPrice={checkout.totalPrice}
            isLoading={isUpdating}
            isAgreed={isAgreed}
            onChangeAgreed={() => setIsAgreed(!isAgreed)}
          />
          <CartButtons
            isShowUpdate={isNeedsUpdate}
            isLoading={isUpdating}
            onUpdate={handleUpdate}
            onCheckout={isAgreed ? handleCheckout : null}
            cartPrismicData={cartPrismicData}
            isAgreed={isAgreed}
            className="flex-grow mt-1 md:mt-4"
          />
          <ResolvingLink
            link={backToShopLink}
            className="underline self-center md:self-end mt-2 md:mt-4"
          >
            {backToShopLinkText}
          </ResolvingLink>
        </div>
      </section>
    </>
  )
}
