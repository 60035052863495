import React from 'react'
import _ from 'lodash'
import RichText from 'prismic-reactjs/src/Component'
import spanHtmlSerializer from '../../utils/spanHtmlSerializer'

export default function CartDetails({
  cartPrismicData,
  subtotalPrice,
  bottleFeePrice,
  totalPrice,
  isLoading,
  isAgreed,
  onChangeAgreed,
  className,
}) {
  const subtotal = _.get(cartPrismicData, 'subtotal_text.text', 'Subtotal')
  const bottleDeposit = _.get(
    cartPrismicData,
    'bottle_deposit_text.text',
    'Bottle Deposit'
  )
  const shippingEstimate = _.get(
    cartPrismicData,
    'shipping_estimate_text.text',
    'Shipping Estimate'
  )
  const taxes = _.get(cartPrismicData, 'taxes_text.text', 'Taxes')
  const total = _.get(cartPrismicData, 'total_text.text', 'Total')
  const calculatedAtNextStep = _.get(
    cartPrismicData,
    'calculated_at_next_step_text.text',
    'Calculated at Next Step'
  )
  const termsRichText = _.get(cartPrismicData, 'terms_checkbox_text.raw', [])

  return (
    <div className="costs flex flex-col px-2 py-2 relative">
      <div className="flex flex-col space-y-2">
        <div className="flex justify-between items-center">
          <p>{subtotal}</p>
          <p>${subtotalPrice}</p>
        </div>
        <div className="flex justify-between items-center">
          <p>{bottleDeposit}</p>
          <p>${bottleFeePrice}</p>
        </div>
        <div className="flex justify-between items-center">
          <p>{shippingEstimate}</p>
          <p className="font-light text-xs">{calculatedAtNextStep}</p>
        </div>
        <div className="flex justify-between items-center">
          <p>{taxes}</p>
          <p className="font-light text-xs">{calculatedAtNextStep}</p>
        </div>
      </div>
      <div className="flex justify-between items-center mt-10">
        <p>{total}</p>
        <p className="font-bold">${totalPrice} CAD</p>
      </div>
      <label className="text-gray-body cursor-pointer select-none mt-4">
        <input
          className="mr-2 leading-tight"
          type="checkbox"
          defaultChecked={isAgreed}
          onChange={onChangeAgreed}
        />
        <span className="select-none">
          <RichText
            render={termsRichText}
            htmlSerializer={spanHtmlSerializer}
          />
        </span>
      </label>
      {isLoading && (
        <div className="bg-white bg-opacity-50 w-full h-full absolute top-0 left-0 flex items-center justify-center">
          <svg
            className="animate-spin ml-2 h-5 w-5 text-yellow"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        </div>
      )}
    </div>
  )
}
